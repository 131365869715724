<template>
  <div>
    <loading v-if="isLoading" />
    <error v-else-if="isError" />
    <b-card v-else>
      <div class="custom-search d-flex justify-content-between mb-1">
        <!-- search input -->
        <b-form-group>
          <!-- <div class="d-flex align-items-center">
            <label class="mr-1">{{ $t("g.searchLabel") }}</label>
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('g.searchHere')"
              type="text"
              class="d-inline-block"
            />
          </div> -->
        </b-form-group>
        <div
          class="d-flex align-items-center"
          v-if="globalPermission.roles && globalPermission.roles.store"
        >
          <b-button variant="primary" :to="{ name: 'add-role' }">
            {{ $t("settings/roles/add-role") }}
          </b-button>
        </div>
      </div>

      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        styleClass="vgt-table"
        :search-options="{
          enabled: true,
          skipDiacritics: true,
          placeholder: $t('search_placeholder'),
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.label === 'name'" class="text-nowrap">
            {{ $t("settings/roles/name") }}
          </span>

          <span v-else-if="props.column.label === 'action'" class="text-nowrap">
            {{ $t("settings/roles/action") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>
        <template slot="emptystate">
          <div class="empty-table-message">
            <!-- Your custom content for the empty state goes here -->
            <p>{{ $t("noData") }}</p>
          </div>
        </template>
        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <!-- Column: Action -->
          <span
            v-if="
              props.column.field === 'action' &&
              globalPermission.roles &&
              (globalPermission.roles.update || globalPermission.roles.destroy)
            "
          >
            <span v-if="props.row.id !== 1">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                text="Block Level Dropdown Menu"
                block
                style="width: 50px"
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                  v-if="globalPermission.roles && globalPermission.roles.update"
                  :to="{ name: 'edit-role', params: { id: props.row.id } }"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{ $t("g.edit") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    globalPermission.roles && globalPermission.roles.destroy
                  "
                  @click="deleteAlert(props.row.id)"
                >
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
            <!-- <span v-else>
              {{ $t("g.noActions") }}
            </span> -->
          </span>
          <span>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{ $t("g.pagelength") }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['5', '10', '20']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                {{ $t("g.of") }} {{ props.total }}
                {{ $t("g.pageText") }}
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BDropdownItem,
  BDropdown,
  VBTooltip,
  BButton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Loading from "@/components/shared/loading/loading.vue";
import Error from "@/components/error/error.vue";
import ContentNotView from "@/components/contentNotView/contentNotView.vue";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import AddModal from "@/components/shared/AddModal/index.vue";
import { gl } from "date-fns/locale";

export default {
  name: "ListRoles",
  components: {
    AddModal,
    BDropdownItem,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BDropdown,
    BButton,
    BCard,
    VueGoodTable,
    ContentNotView,
    Loading,
    Error,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      log: [],
      pageLength: 10,
      searchTerm: "",
      isLoading: true,
      isError: false,
      dir: false,
      form_data: {
        name: "",
      },
      rows: [],
      columns: [
        {
          label: "name",
          field: "name",
        },
        {
          label: "action",
          field: "action",
          sortable: false,
        },
      ],
    };
  },
  created() {
    if (
      this.globalPermission.roles &&
      !(
        this.globalPermission.roles.update ||
        this.globalPermission.roles.destroy
      )
    ) {
      this.columns = this.columns.filter((ele) => ele.label !== "action");
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    // subPermission() {
    //   this.$store.dispatch("GET_PERMISSION", "roles");
    //   return this.$store.state.userData.sub_Permission;
    // },
  },
  beforeMount() {
    this.$http
      .get("admin/roles?guard=web")
      .then((res) => {
        if (res.status === 200) {
          this.rows = res.data.data;
          this.isLoading = false;
        }
      })
      .catch((err) => {
        this.isError = true;
        this.isLoading = false;
        this.$helpers.handleError(err);
      });
  },
  methods: {
    deleteItem(id) {
      this.rows = this.rows.filter((item) => item.id !== id);
    },

    deleteAlert(id) {
      this.$http
        .delete(`admin/roles/${id}`)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            this.deleteItem(id);
            this.$helpers.makeToast(
              "success",
              res.data.message,
              res.data.message
            );
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table";
</style>

<template>
  <b-modal
    centered
    :id="`add-modal-${type}`"
    modal-class="add-modal"
    title-class="add-modal-title"
    header-class="add-modal-header"
    footer-class="add-modal-footer"
    cancel-variant="outline-primary"
    :title="titleModal"
    :ok-title-html="okTitleModal"
    :ok-disabled="isSubmited"
    :cancel-title="$t('cancel-button')"
    @ok="onSubmit"
  >
    <slot />
  </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";

export default {
  name: "AddModal",
  components: { BModal },
  props: {
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: function () {
        return this.$t("add-modal-title").replace(":type");
      },
    },
    okTitle: {
      type: String,
      required: false,
      default: function () {
        return this.$t("save-button");
      },
    },
  },
  data() {
    return {
      titleModal: this.title,
      okTitleModal: this.okTitle,
      isSubmited: false,
    };
  },
  methods: {
    onSubmit(bvModalEvt) {
      bvModalEvt.preventDefault();
      // this.okTitleModal = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>`;
      this.$emit("submit");
      this.okTitleModal = this.okTitle;
      this.isSubmited = false;
    },
  },
};
</script>

<style lang="scss" src="./_index.scss" />

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('loading'):(_vm.isError)?_c('error'):_c('b-card',[_c('div',{staticClass:"custom-search d-flex justify-content-between mb-1"},[_c('b-form-group'),(_vm.globalPermission.roles && _vm.globalPermission.roles.store)?_c('div',{staticClass:"d-flex align-items-center"},[_c('b-button',{attrs:{"variant":"primary","to":{ name: 'add-role' }}},[_vm._v(" "+_vm._s(_vm.$t("settings/roles/add-role"))+" ")])],1):_vm._e()],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"rtl":_vm.direction,"styleClass":"vgt-table","search-options":{
        enabled: true,
        skipDiacritics: true,
        placeholder: _vm.$t('search_placeholder'),
      },"select-options":{
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      },"pagination-options":{
        enabled: true,
        perPage: _vm.pageLength,
      }},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.label === 'name')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("settings/roles/name"))+" ")]):(props.column.label === 'action')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("settings/roles/action"))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.column.label)+" ")])]}},{key:"table-row",fn:function(props){return [(
            props.column.field === 'action' &&
            _vm.globalPermission.roles &&
            (_vm.globalPermission.roles.update || _vm.globalPermission.roles.destroy)
          )?_c('span',[(props.row.id !== 1)?_c('span',[_c('b-dropdown',{staticStyle:{"width":"50px"},attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":"","text":"Block Level Dropdown Menu","block":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[(_vm.globalPermission.roles && _vm.globalPermission.roles.update)?_c('b-dropdown-item',{attrs:{"to":{ name: 'edit-role', params: { id: props.row.id } }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("g.edit")))])],1):_vm._e(),(
                  _vm.globalPermission.roles && _vm.globalPermission.roles.destroy
                )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteAlert(props.row.id)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Trash2Icon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("g.delete")))])],1):_vm._e()],1)],1):_vm._e()]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.pagelength"))+" ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['5', '10', '20']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.of"))+" "+_vm._s(props.total)+" "+_vm._s(_vm.$t("g.pageText"))+" ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])},[_c('template',{slot:"emptystate"},[_c('div',{staticClass:"empty-table-message"},[_c('p',[_vm._v(_vm._s(_vm.$t("noData")))])])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }